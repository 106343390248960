<template>
  <div>
    <b-table
      responsive
      striped
      hover
      show-empty
      empty-text="No matching records found"
      class="table-main table-custom"
      :no-border-collapse="false"
      :items="items"
      :fields="fields"
      :currentPage="filter.page"
      :perPage="filter.take"
    >
      <template v-slot:cell(change_time)="{ item }">
        {{ $moment(item.change_time).format("DD/MM/YYYY (HH:mm:ss)") }}
      </template>
      <template v-slot:cell(remark)="{ item }">
        {{ item.remark || "-" }}
      </template>
      <template v-slot:cell(status_name)="{ item }">
              <div
                :class="{
                  'text-success': item.status_id == 3,
                  'text-gray': item.status_id == 6,
                  'text-danger': item.status_id == 5,
                  'text-warning': item.status_id == 7 || item.status_id == 2 || item.status_id == 1,
                }"
              >
                {{ item.status_name || "-" }}
              </div>
            </template>
      <template v-slot:table-busy>
        <div class="text-center text-black my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-2">Loading...</strong>
        </div>
      </template>
    </b-table>
    <Pagination
      @handleChangeTake="handleChangeTake"
      :pageOptions="pageOptions"
      :filter="filter"
      :rows="items.length"
      @pagination="pagination"
    />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
export default {
  components: { Pagination },

  props: {
    items: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      fields: [
        { key: "status_name", label: "สถานะ" },
        { key: "remark", label: "หมายเหตุ" },
        { key: "change_time", label: "อัพเดทเมื่อ" },
        { key: "changed_by", label: "อัพเดทโดย" },
      ],
      filter: {
        page: 1,
        take: 10,
      },
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
    };
  },
  methods: {
    handleChangeTake() {},
    pagination() {},
  },
};
</script>

<style></style>
